/**
CSS Styling for Marketo RTP forms
*/

#in_zone_form {
  display: block !important;
  width: 100% !important;

  .mktoForm {
    width: 100% !important;
    font-size: 16px !important;

    .no-border-container {
      .mktoFieldDescriptor {
        padding: 0 !important;
      }
      .mktoFieldWrap label {
        margin-left: 0 !important;
      }
    }

    .mktoFormRow {
      margin-bottom: var(--space-lg);

      fieldset {
        padding: 0;
        margin: 0;
        width: 100%;
      }

      .mktoFieldDescriptor {
        padding: var(--space-xs) var(--space-xs) 0;
        background-color: var(--white);
        border: 1px solid var(--greyscale-4);
        box-sizing: border-box;
        float: none !important;

        .mktoOffset {
          display: none !important;
        }
      }

      .mktoRadioList [type="radio"],
      .mktoCheckboxList [type="checkbox"] {
        padding: 0;
        width: 20px !important;
        height: 20px;
        float: left;
      }

      .mktoRadioList,
      .mktoCheckboxList {
        .mktoField {
          min-height: auto !important;
        }
        label {
          float: left;
          margin-left: var(--space-xs) !important;
          margin-bottom: var(--space-sm) !important;
        }
      }
    }

    .mktoFieldWrap {
      float: none;
      width: 100%;

      select {
        border: 0 none;
        margin-bottom: var(--space-xs);
      }

      label.mktoLabel {
        font-weight: normal !important;
        color: inherit;
        margin-left: var(--space-xs);
      }

      .mktoAsterix {
        float: left;
        color: inherit !important;
        //margin-right: var(--space-xs);
      }

      .mktoHtmlText {
        width: 100% !important;
      }
    }

    .mktoButtonWrap {
      margin: 0 !important;
      button.mktoButton {
        background-color: var(--btn-primary-bg);
        color: var(--btn-primary-text);
        border-color: var(--btn-primary-border);
        padding: var(--space-md) var(--space-lg);
        border: 0 none;
      }
    }

    input[type="text"],
    input[type="email"] {
      width: 100% !important;
      border: 0 none;
      outline: 0 none;
      //margin-top:  var(--space-xs);
      padding: 0 0 var(--space-xs) var(--space-xs);
      line-height: 32px;
    }
  }
}

//WDE-4852 for handling the PRODUCTION - FIREFOX - US - FP - Anatomy of a Recession - Subscribe for Updates form - email display issue
@-moz-document url-prefix() {
  input[type="email"] {
    width: 85% !important;
  }
}
