@use "shame";
@use "bloomreach";
@use "../app/ft-components/marketing/marketo-form/vender/marketo-rtp-form";
@use "../app/ft-components/marketing/marketo-form/vender/marketo-popup-form";

// For FT ALTs
@use "node_modules/swiper/swiper-bundle.min.css";

// EDS Fixes needed
.ft__btn {
  text-decoration: none;
}

.grid .grid,
.container .container {
  width: 100%;
  margin: 0; // grids in grids should have no margin
}
// EDS Fixes needed

.has-edit-button {
  position: relative;
}

.no-document {
  margin-bottom: var(--space-xxxl);
  &__config {
    text-align: center;
    display: flex;
  }
  &__component {
    align-items: center;
    background: var(--greyscale-5); //$ft__light-grey;
    border: 1px dashed var(--greyscale-3); //var(--greyscale-3);
    display: flex;
    height: 200px;
    text-align: center;
  }
  &__overlay {
    margin: auto;
  }
  strong {
    font-weight: 700;
  }
}

::ng-deep {
  .highcharts-container {
    position: absolute !important;
    min-width: 0;
    overflow: hidden;
    width: 100% !important;
    svg {
      width: 100% !important;
    }
  }
}
// print styles
@media print {
  //force breaks
  .panel {
    display: block !important;
    position: relative;
    break-inside: avoid-page;
  }

  //space saving making h3 smaller
  h3.title--p {
    font-size: 15pt !important;
  }

  //generic print for core remove grey background color
  .panel--grey {
    background: #fff !important;
  }
  .print-hidden {
    display: none !important;
  }
}

.text-red {
  color: #dc0546;
}
