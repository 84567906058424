.insightera.insightera-dialog {
  padding: 1rem !important;
  box-shadow: var(--box-shadow-basic);
  max-height: 80%;
  overflow-y: auto;
  &:focus-visible {
    outline: 0 !important;
  }

  //Close button
  .close-button-top-right {
    margin-right: 30px !important;

    &:after {
      display: inline-block;
      content: "\00d7";
      color: var(--brand-1);
      font-size: 1.5rem;
    }

    .ui-icon-closethick {
      display: none;
    }
  }

  &.ui-dialog {
    border-radius: 0 !important;
    z-index: 99999999 !important;
  }

  .ui-widget-header {
    background: none !important;
    border: none !important;
    width: 0;
    height: 0;
    cursor: pointer;
  }

  .ui-dialog-content {
    display: inline-block;
  }

  .mktoForm {
    width: auto !important;
    max-width: 600px !important;

    font-family: var(--fonts-base-font-family);
    font-weight: var(--fonts-base-font-weight);
    line-height: var(--fonts-base-line-height);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: var(--space-md);
    }

    .mktoButtonRow {
      margin-top: var(--space-sm) !important;
    }

    h1 {
      font-family: var(--fonts-h1--reg-font-family);
      font-weight: var(--fonts-h1--reg-font-weight);
      line-height: var(--fonts-h1--reg-line-height);
      font-size: var(--fonts-h1--reg-font-size-small-desktop);
    }

    h2 {
      font-family: var(--fonts-h2--reg-font-family);
      font-weight: var(--fonts-h2--reg-font-weight);
      line-height: var(--fonts-h2--reg-line-height);
      font-size: var(--fonts-h2--reg-font-size-small-desktop);
    }

    h3 {
      font-family: var(--fonts-h3--reg-font-family);
      font-weight: var(--fonts-h3--reg-font-weight);
      line-height: var(--fonts-h3--reg-line-height);
      font-size: var(--fonts-h3--reg-font-size-small-desktop);
    }

    p {
      font-family: var(--fonts-p-font-family);
      font-weight: var(--fonts-p-font-weight);
      line-height: var(--fonts-p-line-height);
      font-size: var(--fonts-p-font-size-desktop);
    }

    .mktoField {
      font-family: var(--fonts-p-font-family);
      font-weight: var(--fonts-p-font-weight);
      line-height: var(--fonts-p-line-height);
      font-size: var(--fonts-p-font-size-desktop);
    }

    .mktoFormCol {
      width: 100%;

      .mktoOffset {
        display: none !important;
      }
    }

    .mktoFieldWrap {
      float: none !important;
      .mktoHtmlText {
        width: auto !important;
      }
      .mktoGutter {
        display: none !important;
      }
    }

    .mktoFieldDescriptor {
      background-color: var(--white);
      margin-bottom: var(--space-md) !important;
    }

    .mktoLabel {
      font-family: var(--fonts-small-font-family);
      font-weight: var(--fonts-small-font-weight);
      line-height: var(--fonts-small-line-height);
      font-size: var(--fonts-p-font-size-desktop);
      width: 100% !important;
    }

    .mktoRequiredField .mktoAsterix {
      margin-right: 0 !important;
      float: left !important;
      padding-right: 5px !important;
    }

    .mktoCheckboxList,
    .mktoRadioList {
      width: 100% !important;
      display: block;
      padding-left: 0 !important;

      > label {
        font-family: var(--fonts-small-font-family);
        font-weight: var(--fonts-small-font-weight);
        line-height: var(--fonts-small-line-height);
        font-size: var(--fonts-p-font-size-desktop);
      }
    }

    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    textarea.mktoField,
    select.mktoField {
      border: 0.0625rem solid var(--greyscale-4);
      margin: 0.5rem 0 0 0 !important;
      padding: 0.6rem !important;
      width: 100% !important;
      line-height: 2.5em;
      color: var(--input-text-color);
    }

    input[type="checkbox"],
    input[type="radio"] {
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
      padding: 10px;
      margin-top: 5px;
    }

    .mktoButtonWrap {
      margin-left: 0 !important;

      .mktoButton {
        background-color: var(--btn-primary-bg) !important;
        color: var(--btn-primary-text) !important;
        font-family: var(--btn-font-family) !important;
        font-size: 1rem;
        padding: 1.0625rem 1.625rem;
        line-height: 1rem;
        width: auto !important;
        border: 0 none;
      }
    }

    // Error
    .mktoError {
      right: 0 !important;
      .mktoErrorArrowWrap {
        display: none;
      }

      .mktoErrorMsg {
        margin-top: 3px;
        background-color: var(--white);
        border: 0 none;
        color: var(--error);
        background-image: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        padding: 0 !important;
        font-family: var(--fonts-p-font-family);
      }
    }
  }
}

// mobile

@media screen and (max-width: 480px) {
  .insightera.insightera-dialog {
    width: 80% !important;

    .mktoForm {
      padding: 0 !important;

      h1,
      h2,
      h3 {
        padding-left: 0;
      }

      input[type="text"],
      input[type="url"],
      input[type="email"],
      input[type="tel"],
      input[type="number"],
      input[type="date"],
      textarea.mktoField,
      select.mktoField {
        min-height: 3rem;
      }
    }
  }

  .insightera.insightera-dialog-transparent {
    .ui-dialog-titlebar-close {
      width: 25px;
    }

    .close-button-top-right {
      margin-right: 5px !important;
      margin-top: -12px !important;
    }

    .ui-dialog-content img {
      max-width: 312px;
      height: auto;
    }
  }
}

.insightera.insightera-dialog-basic {
  .ui-dialog-titlebar-close {
    width: auto !important;
    &:hover {
      padding: 1px;
    }
  }
  .close-button-top-right {
    right: 50px !important;

    &:after {
      position: absolute;
      top: -2px;
    }
  }
}

// Marketo - fly-in cards
.insightera.insightera-dialog-transparent {
  padding: 0 !important;
  box-shadow: none;

  .close-button-top-right {
    margin-right: 15px !important;
    margin-top: -17px !important;

    &:after {
      display: none;
      color: var(--brand-1);
      font-size: 1.5rem;
    }
  }

  .ui-dialog-titlebar-close {
    height: 38px;
    padding: 0;
    width: 40px;
    margin: 5px 0 0;
    right: 0.1em;
    background-repeat: no-repeat;
  }

  .ui-helper-clearfix:after {
    content: none;
  }
}
