/**
 All our shameful hacks go here
 ------------------------------------------------------------
**/

@use "eds-components/src/scss/abstracts/functions";
:root {
  --fonts-alts-h1-font-size-mobile: 36px;

  --fonts-alts-h1-font-size-tablet: 78px;
  --fonts-alts-h1-font-size-small-desktop: 78px;
  --fonts-alts-h1-font-size-desktop: 78px;
  --fonts-alts-h1-font-size-large-desktop: 78px;
  --fonts-alts-h2-font-size-mobile: 32px;
  --fonts-alts-h2-font-size-tablet: 56px;
  --fonts-alts-h2-font-size-small-desktop: 56px;
  --fonts-alts-h2-font-size-desktop: 56px;
  --fonts-alts-h2-font-size-large-desktop: 56px;
  --fonts-alts-h3-font-size-mobile: 28px;
  --fonts-alts-h3-font-size-tablet: 44px;
  --fonts-alts-h3-font-size-small-desktop: 44px;
  --fonts-alts-h3-font-size-desktop: 44px;
  --fonts-alts-h3-font-size-large-desktop: 44px;
  --fonts-alts-h4-font-size-mobile: 24px;
  --fonts-alts-h4-font-size-tablet: 36px;
  --fonts-alts-h4-font-size-small-desktop: 36px;
  --fonts-alts-h4-font-size-desktop: 36px;
  --fonts-alts-h4-font-size-large-desktop: 36px;
  --fonts-alts-p-lrg-font-size-mobile: 16px;
  --fonts-alts-p-lrg-font-size-tablet: 18px;
  --fonts-alts-p-lrg-font-size-small-desktop: 18px;
  --fonts-alts-p-lrg-font-size-desktop: 18px;
  --fonts-alts-p-lrg-font-size-large-desktop: 18px;
  --fonts-alts-p-font-size-mobile: 16px;
  --fonts-alts-p-font-size-tablet: 16px;
  --fonts-alts-p-font-size-small-desktop: 16px;
  --fonts-alts-p-font-size-desktop: 16px;
  --fonts-alts-p-font-size-large-desktop: 16px;
  --fonts-alts-small-font-size-mobile: 13px;
  --fonts-alts-small-font-size-tablet: 13px;
  --fonts-alts-small-font-size-small-desktop: 13px;
  --fonts-alts-small-font-size-desktop: 13px;
  --fonts-alts-small-font-size-large-desktop: 13px;

  --fonts-alts-stats-small-font-size-mobile: 32px;
  --fonts-alts-stats-small-font-size-tablet: 48px;

  --fonts-alts-h5-font-size-mobile: 24px;
  --fonts-alts-h5-font-size-tablet: 36px;
  --fonts-alts-h5-font-size-small-desktop: 36px;
  --fonts-alts-h5-font-size-desktop: 36px;
  --fonts-alts-h5-font-size-large-desktop: 36px;
}
/**
 Nested Container hack/fix
 ------------------------------------------------------------
**/
.grid .grid,
.container .container {
  width: 100%;
  margin: 0; // grids in grids should have no margin
}

/**
 Vendor hacks/fixes
 ------------------------------------------------------------
**/
::ng-deep {
  .highcharts-container {
    position: absolute !important;
    min-width: 0;
    overflow: hidden;
    width: 100% !important;
    svg {
      width: 100% !important;
    }
  }
}

/**
 * NGC-8173 - Adding following CSS as there is no component for single card in a section
 */
.fund-card--single {
  padding: functions.rem(80) 0 functions.rem(80) functions.rem(104);
  background: var(--greyscale-5); //$ft__light-grey;
}

/**
* NGC-8452 -
* @Todo - Need to check why this changes from EDS not working as expected on avatar.component.scss
*/
.avatar__description p {
  margin: 0;
}

/**
 * WDE-2110
 * Previously there was CSS hacked in GTM containers to hide tracking px images
 * Instead, this will take them out of the flow of the document, so they don't create whitespace at bottom of page
 * the body > img selector is used on the assumption that any images that should be displayed will exist within <app-root>
*/
body > img {
  position: absolute;
  top: 0;
  left: 0;
}
/** EMEA styles
 * DCE-1599 - Alternatives style hacks for core use
 */

ft-alts-podcast-hero {
  background: linear-gradient(235deg, #151180, #3769ff 40%, #4b00b6) no-repeat;
  display: block;
  overflow: hidden;
  margin: 0 2rem;
  position: relative;
}

.alts-podcast-boxes__box-title {
  font-size: 24px;
}

.alts-podcast-boxes__title {
  font-size: 36px;
}

ft-alts-podcast-hero .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

ft-alts-podcast-hero .row .col.l10 {
  width: 83.3333333333%;
}

ft-alts-podcast-hero .alts-banner-podcast-landing__content-left h1 {
  font-size: 56px;
}

.alts-podcast-latest__title {
  font-size: 36px;
}

.alts-listen-on-top {
  top: 170px !important;
}

.alts-staggered-cards,
.alts-staggered-cards-v4 {
  background: linear-gradient(220deg, #00236b 10%, #1446e1 40%, #4037b9 85%);
  background-repeat: no-repeat;
  background-size: auto 40%;
  margin-top: 0 !important;
}
.alts-staggered-cards-v4__title {
  font-size: functions.rem(24) !important;

  @media (min-width: functions.rem(768)) {
    font-size: functions.rem(36) !important;
  }
}
.alts-staggered-cards-v4__column-right,
.alts-staggered-cards-v4__article-wrapper-left {
  h3 {
    font-size: functions.rem(20);
  }
  @media (min-width: functions.rem(768)) {
    h3 {
      font-size: functions.rem(24);
    }
  }
}
.alts-staggered-cards-v4__column-image-content__description {
  @media (min-width: functions.rem(768)) {
    p,
    li {
      font-size: functions.rem(18);
    }
    a {
      font-size: functions.rem(16);
    }
  }
}

.alts-tiles-with-text {
  background: none;
  background-repeat: no-repeat;
  background-size: auto 40%;
  padding-top: var(--space-lg) !important;
  &__content {
    color: var(--white) !important;
    margin-bottom: var(--space-md) !important;
    margin-left: var(--space-xs) !important;
    h2 {
      color: var(--white) !important;
      font-size: functions.rem(32) !important;
    }
    @media (min-width: functions.rem(768)) {
      h2 {
        font-size: functions.rem(56) !important;
      }
      p {
        font-size: functions.rem(18);
      }
    }
  }
}

.alts-tile-with-text__tile {
  z-index: 1;
}

.alts-tiles-with-text__tile-data {
  h3 {
    font-size: functions.rem(20) !important;
  }
  @media (min-width: functions.rem(768)) {
    h3 {
      font-size: functions.rem(24) !important;
    }
    p {
      font-size: functions.rem(18);
    }
  }
}

// banner hacks
.alts-banner-with-image-and-statistics__content-heading {
  //
  font-size: functions.rem(36);
  @media (min-width: functions.rem(768)) {
    font-size: functions.rem(78);
    line-height: 1.1;
  }
}

.alts-banner-with-image-and-statistics__content-description p {
  font-size: functions.rem(18);
}
.specialist-tabs__section-description__first {
  font-size: functions.rem(32);
  @media (min-width: functions.rem(768)) {
    font-size: functions.rem(56);
  }
}
.specialist-tabs__panel-content__manager {
  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
}
.alts-portfolio-success__heading,
.investment-strategies__title-desc {
  h2 {
    font-size: functions.rem(32);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(56);
    }
  }

  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
}
.alts-invest-strategies {
  .swiper-wrapper {
    h3,
    p {
      font-size: functions.rem(16);
    }
    @media (min-width: functions.rem(768)) {
      h3,
      p {
        font-size: functions.rem(18);
      }
    }
  }
}

.knowledge-hub__content__banner {
  h2 {
    font-size: functions.rem(32);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(56);
    }
  }
}
.knowledge-hub__content__article__col-left__padding--side {
  h3 {
    font-size: functions.rem(24);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(32);
    }
  }
}
.knowledge-hub__content__article__col-right {
  h3 {
    font-size: functions.rem(24);
  }
}

.alts-knowledge-card-carousel__wrapper {
  .title--h3 {
    font-size: functions.rem(28);
    font-family: var(--font-bold);
  }
  .title--h6 {
    font-size: functions.rem(20);
    font-family: var(--font-bold);
  }

  @media (min-width: functions.rem(768)) {
    .title--h3 {
      font-size: functions.rem(44);
    }
    .title--h6 {
      font-size: functions.rem(24);
    }
  }
}

.alts-knowledge-card__heading.title--h3 {
  font-size: functions.rem(28);
  font-family: var(--font-bold);
  @media (min-width: functions.rem(768)) {
    font-size: functions.rem(44);
  }
}

//generic style unavoidable this is how it's coming in from ftalts
.alts-banner-container {
  .title--h3.banner-wrapper__content-title {
    font-size: functions.rem(28);
    font-family: var(--fonts-h2--reg-font-family);
    font-weight: var(--fonts-h2--reg-font-weight);
    line-height: var(--fonts-h2--reg-line-height);

    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(44);
    }
    span {
      font-weight: 400;
    }
  }
  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
}

.alts-portfolio-success-tabs__button {
  font-size: functions.rem(18) !important;
}
.alts-portfolio-success-component {
  h3 {
    font-size: functions.rem(20) !important;
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(24) !important;
    }
  }
  &__stats-1 {
    .title--h4 {
      font-size: functions.rem(24) !important;
      font-weight: 600 !important;
      @media (min-width: functions.rem(768)) {
        font-size: functions.rem(36) !important;
      }
    }
  }
}
.alts-portfolio-success {
  .font-caveats p {
    font-size: rem(14) !important;
  }
}
//ft alts glossary - expecting naming collisions with this one possibily - may need to add alts to the name in ft-alts
.alts-glossary {
  h2 {
    font-size: functions.rem(32);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(56);
    }
  }
  .alts-glossary__list-wrapper-title,
  .alts-glossary__list-wrapper-description {
    font-size: functions.rem(16);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(18);
    }
  }
}
.alts-banner__content-subheading {
  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
}
.alts-infographic-circle__top-text-wrapper {
  h2 {
    font-size: functions.rem(32);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(56);
    }
  }
  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
}
.alts-infographic-circle__tooltip-container {
  .alts-infographic-circle__tooltip-title {
    font-size: functions.rem(20);
  }
  .list-class li {
    font-size: functions.rem(16);
  }
  @media (min-width: functions.rem(768)) {
    .alts-infographic-circle__tooltip-title {
      font-size: functions.rem(24);
    }
    .list-class li {
      font-size: functions.rem(18);
    }
  }
}

.ft-alts-animated-chart-in-banner__col-left {
  h2 {
    font-size: functions.rem(32);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(56);
    }
  }
}
.alts-banner-v2__col-text {
  h2 {
    font-size: functions.rem(32);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(56);
    }
  }
  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
}
// if only one card, align single card left
.alts-ft-boxes-with-buttons {
  .row:has(.col.s4.m4.l5:only-child) {
    justify-content: flex-start;
  }
}

.alts-ft-boxes-with-buttons__content {
  .title--h4 {
    font-size: functions.rem(24);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(36);
    }
  }
}

.alts-timeline {
  h2 {
    font-size: functions.rem(32);
  }
  h3 {
    font-size: functions.rem(20);
  }
  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h3 {
      font-size: functions.rem(24);
    }
    .alts-align-center p {
      font-size: functions.rem(18);
    }
  }
}
//using this unfortunate set up,  don't want a collision with ft styles- this will need to be adjusted in ftalts
.alts-portfolio-managers {
  h2 {
    font-size: functions.rem(32);
  }
  h3 {
    font-size: functions.rem(20);
  }
  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h3 {
      font-size: functions.rem(24);
    }
    p {
      font-size: functions.rem(18);
    }
  }
}
.alts-custom-chart {
  h2 {
    font-size: functions.rem(32);
  }
  h4 {
    font-size: functions.rem(24);
  }
  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h4 {
      font-size: functions.rem(36);
    }
  }

  .gg-potential-attractive-returns {
    .title--h4 {
      font-size: functions.rem(24);
    }
    @media (min-width: functions.rem(768)) {
      .title--h4 {
        font-size: functions.rem(36);
      }
      .table-chart-v1 th {
        font-size: functions.rem(18);
      }
    }
  }
}
.alts-custom-tabs {
  h2 {
    font-size: functions.rem(32);
  }
  h3 {
    font-size: functions.rem(24);
    font-family: var(--font-bold);
  }
  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h3 {
      font-size: functions.rem(36);
    }
    p {
      font-size: functions.rem(18);
    }
  }
}

.alts-staggered-cards,
.alts-staggered-cards-v3 {
  h2 {
    font-size: functions.rem(28);
    font-family: var(--font-bold);
  }
  h3 {
    font-size: functions.rem(20);
  }

  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(44);
    }
    h3 {
      font-size: functions.rem(24);
    }
    p {
      font-size: functions.rem(18);
    }
    li {
      font-size: functions.rem(18);
    }
  }
}
.alts-stages-slider {
  h2 {
    font-size: functions.rem(28);
    font-family: var(--font-bold);
    .title--h6 {
      font-size: functions.rem(20);
    }
  }

  h3 {
    font-size: functions.rem(24);
    font-family: var(--font-bold);
  }
  .alts-stages-slider__slide-box-icons .font-p-lrg--b {
    font-size: functions.rem(16);
  }

  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(44);
    }
    h3 {
      font-size: functions.rem(44);
    }
    .title--h6 {
      font-size: functions.rem(24);
    }
    .alts-stages-slider__slide-box-icons .font-p-lrg--b {
      font-size: functions.rem(18);
    }
  }
}

.alts-three-col-text-image__column-wrapper {
  h2 {
    font-size: functions.rem(32);
  }
  h3 {
    font-size: functions.rem(20);
  }

  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h3 {
      font-size: functions.rem(24);
    }
    li {
      font-size: functions.rem(18);
    }
  }
}
.alts-slider-with-images {
  h2 {
    font-size: functions.rem(32);
  }
  h3 {
    font-size: functions.rem(28);
  }

  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h3 {
      font-size: functions.rem(36);
    }
  }
}
.alts-info-table {
  .color--ft-sky-blue.font-p-lrg--b.pc-diagram {
    font-size: functions.rem(16);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(18);
    }
  }
}

.alts-staggered-cards-v2 {
  .alts-staggered-cards-v2__title {
    font-size: functions.rem(28);
    font-family: var(--font-bold);
  }
  h3.title--h6 {
    font-size: functions.rem(20);
  }
  h6 {
    font-size: functions.rem(20);
  }

  @media (min-width: functions.rem(768)) {
    .alts-staggered-cards-v2__title {
      font-size: functions.rem(44);
    }
    h3.title--h6 {
      font-size: functions.rem(24);
    }
    h6 {
      font-size: functions.rem(24);
    }
    p,
    li {
      font-size: functions.rem(18);
    }
  }
}

.alts-explore-graph {
  .alts-explore-graph__col-title {
    h2.title--h2 {
      font-size: functions.rem(32);
    }
    h3 {
      font-size: functions.rem(20);
    }

    @media (min-width: functions.rem(768)) {
      h2.title--h2 {
        font-size: functions.rem(56);
      }
      h3 {
        font-size: functions.rem(24);
      }
      p {
        font-size: functions.rem(18);
      }
    }
  }
}

// carousel
.alts-carousel__text-content {
  .alts-carousel__text-content__content {
    h2 {
      font-size: functions.rem(32);
    }
    h3 {
      font-size: functions.rem(20);
    }

    @media (min-width: functions.rem(768)) {
      h2 {
        font-size: functions.rem(56);
      }
      h3 {
        font-size: functions.rem(24);
      }
      p {
        font-size: functions.rem(18);
      }
    }
  }
}
.alts-carousel__swiper-container {
  .alts-carousel__slide-label-name {
    font-size: functions.rem(20);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(24);
    }
  }
  .alts-carousel__slide-property-type {
    font-size: functions.rem(16);
    @media (min-width: functions.rem(768)) {
      font-size: functions.rem(18) !important;
    }
  }
}

// fix hero spacing top
.alts-banner__content {
  margin-top: 0 !important;
  padding-top: functions.rem(65) !important;
  @media (min-width: functions.rem(768)) {
    padding-top: functions.rem(160) !important;
  }

  // add margin if background image is present

  .alts-banner__content-right {
    @media (min-width: functions.rem(768)) {
      margin-right: 16px;
    }
    @media (min-width: functions.rem(1024)) {
      margin-right: 24px;
    }
    @media (min-width: functions.rem(1200)) {
      margin-right: 21px;
    }
    @media (min-width: functions.rem(1600)) {
      margin-right: 24px;
    }
  }
}

//add missing bg from alts layout missing gradient background container
// target page layout container (br-container) and find sticky nav if child of br-container, add the background gradient
.br-container-box:has(div):has(.alts-sticky-nav) {
  @extend .alts-specialist-category-section;
}
// for staggered cards container, reduce background size so cards retain white space
.br-container-box:has(div):has(.alts-staggered-cards-v4) {
  background-size: auto 70% !important;
}

.br-container-box:has(div):has(.alts-tiles-with-text) {
  background: linear-gradient(220deg, #00236b 10%, #1446e1 40%, #4037b9 85%)
    no-repeat !important;
  background-size: auto 60% !important;
}
// hide background color since the bg is coming from the br-container now - leave for now in case we decide to add the bg color in BR as an option
.alts-staggered-cards-v4 {
  background: none !important;
}

// background gradients for specialist and category
.alts-specialist-category-section {
  background: linear-gradient(235deg, #151180, #3769ff 40%, #4b00b6) no-repeat;
  margin-bottom: 96px;
}
.alts-homepage-section {
  background: linear-gradient(220deg, #00236b 10%, #1446e1 40%, #4037b9 85%);
  margin-bottom: 96px;
}

.spacing--mg {
  @media (min-width: functions.rem(768)) {
    margin-bottom: 96px;
  }
  margin-bottom: 56px;
}

.banner-wrapper.padding-bottom--mg {
  padding-bottom: 0;
}

//hide scroll
.homepage .alts-scroll-line {
  display: none;
}

// card tiles overwrite ft.com global style
.alts-tiles-with-text__tile-data {
  // remove extra spacing added by ft.com css
  p:last-child {
    margin-bottom: 0;
  }
}

// Generic
.alts-html {
  h2 {
    font-size: functions.rem(32);
  }
  h2.title--h2 {
    font-size: functions.rem(32);
  }
  .title--h4 {
    font-size: functions.rem(24);
    font-family: var(--font-bold);
  }
  .title--h5 {
    font-size: functions.rem(24);
    font-family: var(--font-regular);
  }
  h3.title--h6 {
    font-size: functions.rem(20);
  }
  .font-p-lrg--b {
    font-size: functions.rem(16);
  }
  .font-stats {
    font-size: functions.rem(56);
  }
  .title.font-p-lrg {
    font-size: functions.rem(16);
  }

  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    h2.title--h2 {
      font-size: functions.rem(56);
    }
    h3.title--h6 {
      font-size: functions.rem(24);
    }
    .title--h4 {
      font-size: functions.rem(36);
      font-family: var(--font-bold);
    }
    .title--h5 {
      font-size: functions.rem(36);
      font-family: var(--font-regular);
    }
    p {
      font-size: functions.rem(18);
    }
    .font-p-lrg--b {
      font-size: functions.rem(18);
    }
    .font-stats {
      font-size: functions.rem(72);
    }
    .title.font-p-lrg {
      font-size: functions.rem(18);
    }
  }
}

.alts-insight-box__details {
  .title--h6 {
    font-size: functions.rem(20);
  }
  @media (min-width: functions.rem(768)) {
    .title--h6 {
      font-size: functions.rem(24);
    }
  }
}

.gg-presence-maps {
  .title--h2 {
    font-size: functions.rem(32);
  }

  @media (min-width: functions.rem(768)) {
    .title--h2 {
      font-size: functions.rem(56);
    }
  }
}
.gg-presence-maps__legend-item.font-small {
  font-size: functions.rem(13);
}
.gg-ft-data-graph {
  @media (min-width: functions.rem(768)) {
    p {
      font-size: functions.rem(18);
    }
  }
  .component-footnotes {
    @media (min-width: functions.rem(768)) {
      p {
        font-size: functions.rem(16);
      }
    }
  }
}
.table-chart-v2__scale--secondary p {
  font-size: functions.rem(13);
}
.alts-interactive-map__content {
  h2 {
    font-size: functions.rem(32);
  }

  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56);
    }
    & > p {
      font-size: functions.rem(18);
    }
  }
}

// products
.alts-boxes-with-content {
  // force opacity
  &:not(.active) {
    .alts-key-features {
      &__animation-container {
        opacity: unset !important;
        transform: unset !important;
      }
    }
  }
  // fix padding
  .padding-top--xxxl {
    padding-top: var(--space-xl);
  }
}

.alts-boxes-with-content__column-right-table {
  td.alts-boxes-with-content__col-3:first-child {
    text-align: left;
  }
}
// fix ft css overwrite - video
.alts-video__title.title--h2:not(.color--ft-black) {
  font-size: functions.rem(32);
  color: #fff !important;

  @media (min-width: functions.rem(768)) {
    font-size: functions.rem(56);
  }
}

// adding missing grid to main container if hero basic is present
.br-container-box:has(ft-alts-hero-basic) {
  // mock grid
  margin: 0 0.5rem;

  @media (min-width: functions.rem(768)) {
    margin: 0 0.25rem;
  }
  @media (min-width: functions.rem(1200)) {
    margin: 0 0.25rem;
  }
  @media (min-width: functions.rem(1600)) {
    margin: 0 3rem;
  }
  @media (min-width: 1900px) {
    margin: 0 5rem;
  }
}

// fix alignment
.alts-banner-with-image-and-statistics__fund-wrapper {
  @media (min-width: functions.rem(768)) {
    padding: 0 16px !important;
  }
  @media (min-width: functions.rem(1024)) {
    padding: 0 24px !important;
  }
  @media (min-width: functions.rem(1200)) {
    padding: 0 20px !important;
  }
  @media (min-width: functions.rem(1600)) {
    padding: 0 24px !important;
  }
}
.alts-banner-with-image-and-statistics__content-right {
  @media (min-width: functions.rem(1600)) {
    padding: 161px 31px 0 0 !important;
  }
}
// force thumb on range selector to appear
.alts-range-selector input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  height: 2rem;
  width: 1rem;
  border: 1px solid #bfbfbf;
}
ft-alts-layout {
  .gg-switcher-animation {
    @media (min-width: functions.rem(1024)) {
      top: 163px !important;
    }

    @media (min-width: functions.rem(1200)) {
      top: 180px !important;
    }
  }
}
ft-alts-layout .grid {
  h1:not([class*="title--"], [class*="font-"]),
  h2:not([class*="title--"], [class*="font-"]),
  h3:not([class*="title--"], [class*="font-"]),
  h4:not([class*="title--"], [class*="font-"]) {
    font-weight: 700;
  }
  h5:not([class*="title--"], [class*="font-"]) {
    font-weight: 400;
    font-family: var(--font-regular);
  }
  h1:not([class*="title--"], [class*="font-"]),
  [class*="h1"] {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-h1-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-h1-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-h1-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-h1-font-size-large-desktop);
    }
  }
  h2:not([class*="title--"], [class*="font-"]),
  [class*="h2"] {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-h2-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-h2-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-h2-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-h2-font-size-large-desktop);
    }
  }
  h3:not([class*="title--"], [class*="font-"]),
  [class*="h3"] {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-h3-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-h3-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-h3-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-h3-font-size-large-desktop);
    }
  }
  h4:not([class*="title--"], [class*="font-"]),
  [class*="h4"] {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-h4-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-h4-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-h4-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-h4-font-size-large-desktop);
    }
  }
  h5:not([class*="title--"], [class*="font-"]),
  [class*="h5"] {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-h5-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-h5-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-h5-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-h5-font-size-large-desktop);
    }
  }
  .font-p,
  .font-p--b,
  strong {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-p-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-p-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-p-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-p-font-size-large-desktop);
    }
  }
  body,
  p:not([class*="font-"]),
  .font-p-lrg,
  .font-p-lrg--b {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-p-lrg-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-p-lrg-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-p-lrg-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-p-lrg-font-size-large-desktop);
    }
  }
  small,
  .font-small--b {
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-small-font-size-mobile);
    }
    @media (min-width: functions.rem(1024)) {
      font-size: var(--fonts-alts-small-font-size-small-desktop);
    }
    @media (min-width: functions.rem(1200)) {
      font-size: var(--fonts-alts-small-font-size-desktop);
    }
    @media (min-width: functions.rem(1600)) {
      font-size: var(--fonts-alts-small-font-size-large-desktop);
    }
  }
  .font-stats-small {
    font-size: var(--fonts-alts-stats-small-font-size-mobile);
    @media (min-width: functions.rem(768)) {
      font-size: var(--fonts-alts-stats-small-font-size-tablet);
    }
  }

  p.legend-item__value {
    font-size: functions.rem(16);
  }

  margin: 0 0.5rem;
  @media (min-width: functions.rem(768)) {
    margin: 0 0.25rem;
  }
  @media (min-width: functions.rem(1200)) {
    margin: 0 2rem;
  }
  @media (min-width: 1900px) {
    margin: 0 5rem;
  }
  &.spacing--mg {
    margin-bottom: functions.rem(56);
  }

  // mock grid
  &:not([class*="padding-"], [class*="perf-footer__section"]) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.padding-left--xxxl,
  .padding-left--xxxl {
    padding-left: var(--space-xxl);
  }
  .padding-right--xxxl {
    padding-right: var(--space-xxl);
  }

  .padding-top--xxxl {
    padding-top: var(--space-xxl);
  }
  .padding-bottom--xxxl {
    padding-bottom: var(--space-xxl);
  }
  .knowledge-hub__content__article__col-right {
    padding: var(--space-md) var(--space-xxl) var(--space-md) var(--space-xxl);
  }
  .padding-left--xs {
    padding-left: var(--space-sm);
  }
  .padding-right--xs {
    padding-right: var(--space-sm);
  }

  .alts-product-banner__content-right {
    padding-right: var(--space-sm);
  }
}

.alts-block-button {
  a {
    text-decoration: none !important;
  }
}

.dropdown__list-element {
  p {
    margin-bottom: 0;
  }
}

.open {
  .dropdown__list {
    overflow-y: scroll !important;
    max-height: 180px !important;
  }
}

.alts-animated-piechart__slide {
  h2 {
    font-size: functions.rem(32) !important;
  }
  @media (min-width: functions.rem(768)) {
    h2 {
      font-size: functions.rem(56) !important;
    }
  }
}

.alts-homepage-hero {
  .alts-video__title {
    color: var(--white) !important;
  }
}

.gg-deep-experience__data-numbers-title {
  font-size: functions.rem(78) !important;
}

.gg-deep-experience__data-numbers-desc {
  font-size: functions.rem(18) !important;
}

ft-alts-perf-table .perf-table sup span {
  font-size: 10px;
  font-weight: 600 !important;
}

ft-alts-portfolio-custom-legend .portfolio-custom-legend__name {
  margin-bottom: 0px;
}
ft-alts-data-table > table > thead > tr {
  th.data-table-zebra__thead-name,
  th.data-table-zebra__thead-value {
    font-weight: bold;
  }
}
ft-alts-product-layout > aside {
  left: 0px !important;
  z-index: -1 !important;
}
ft-alts-layout ft-footer eds-footer ul .social-media__list-item a,
.social-media__list-item a {
  width: auto !important;
}
ft-alts-perf-tab-discrete-average-annual .pt-monthly-calendar__table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ft-alts-footnotes sup span {
  font-weight: bold;
}
